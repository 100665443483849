.App-header {
  background-color: #416487;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #416487;
}

img.App-logo {
  width: 150px;
  cursor: pointer;
}

.close-button:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.image-list {
  width: 100%;
  overflow: hidden;
}

.image-list p {
  margin: 0;
  font-size: 0.5em;
}

.avatar {
  margin-top: 10px;
  position: absolute;
  right: 10px;
}

.list-header {
  display: inline-flex;
}
.overlay {
  position: fixed;
  color: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img:hover {
  cursor: pointer;
  transform: scale(1.01);
}

.mt-0 {
  margin-top: 0;
}

.location-lock {
  display: block;
}

.subtitle {
   margin-left: 10px; 
   font-size: 14px;
}

.register {
  padding: 0 40px 0 40px !important;
}

.about {
  text-align: center;
  padding: 0 60px 0 60px !important;
}

.about-inner {
  text-align: left;
  padding: 0 25%;
}

@media (max-width: 768px) {
  .about {
    padding: 0 20px 0 20px !important;
  }
  
  .about-inner {
    padding: 0 10%;
  }
}

@media (max-width: 400px) {
  .register {
    padding: 0 !important;
  }
  .about {
    padding: 0 !important;
  }
  
  .about-inner {
    padding: 0 !important;
  }
  .subtitle {
    display: none;
  }
}
